import { settingConstants } from "../_constants";
console.log("settingConstants.GET_REFERRAL_LEVEL_REQUEST============>>>>>>>>>>>>>>>",settingConstants.GET_REFERRAL_LEVEL_SUCCESS);
export function refferLevel_reducer(state = {}, action) {
  //console.log("action.typeaction.typeaction.type  ", action); 
  console.log("actionnnnnnnnnnnnnnnn from refferal reducerrrrrrrrrrrrrrrrrr",action);
  switch (action.type) {

 
    // case settingConstants.GET_REFERRAL_LEVEL_REQUEST:
    //   return {
    //     loading: true
    //   };
    // case settingConstants.GET_REFERRAL_LEVEL_SUCCESSs:
    //   return {
    //     referralLevels:action.setting.referralLevels,
    //   };
    // case settingConstants.GET_REFERRAL_LEVEL_FAILURE:
    //   return {
    //     error: action.error
    //   };

    case settingConstants.UPDATE_REFERRAL_REQUEST:
      return {
        loading: true,
        referralLevels: state.referralLevels
      };
    case settingConstants.UPDATE_REFERRAL_SUCCESS:
      return {
        referralLevels:state.referralLevels,
        isSettingUpdate:true
      };
    case settingConstants.UPDATE_REFERRAL_FAILURE:
      return {
        referralLevels:state.referralLevels,
        error: action.error
      };
    default:
      return state;
  }
}
