export const userConstants = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
    
    LOGOUT: 'USERS_LOGOUT',

    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',
    
    UPDATE_USER_STATUS_SUCCESS: 'USERS_UPDATE_USER_STATUS_SUCCESS',
    UPDATE_USER_STATUS_FAILURE: 'USERS_UPDATE_USER_STATUS_FAILURE',

    GETALL_REQUEST_VIEWCONTACTDETAILS: 'USERS_GETALL_REQUEST_VIEWCONTACTDETAILS',
    GETALL_SUCCESS_VIEWCONTACTDETAILS: 'USERS_GETALL_SUCCESS_VIEWCONTACTDETAILS',
    // GETALL_FAILURE_VIEWCONTACTDETAILS: 'USERS_GETALL_FAILURE_VIEWCONTACTDETAILS',

    GETALL_REQUEST_NOTIFICATION: 'USERS_GETALL_REQUEST_NOTIFICATION',
    GETALL_SUCCESS_NOTIFICATION: 'USERS_GETALL_SUCCESS_NOTIFICATION',
    GETALL_FAILURE_NOTIFICATION: 'USERS_GETALL_FAILURE_NOTIFICATION',

    GETALL_REQUEST_CONTACT: 'USERS_GETALL_REQUEST_CONTACT',
    GETALL_SUCCESS_CONTACT: 'USERS_GETALL_SUCCESS_CONTACT',
    GETALL_FAILURE_CONTACT: 'USERS_GETALL_FAILURE_CONTACT',

    REQUEST_INFO_USER_INFO: 'REQUEST_INFO_USER_INFO',
    SUCCESS_INFO_USER_INFO: 'SUCCESS_INFO_USER_INFO',
    FAILURE_INFO_USER_INFO: 'FAILURE_INFO_USER_INFO',

    UPDATE_KYC_REQUEST: 'UPDATE_KYC_REQUEST',
    UPDATE_KYC_SUCCESS: 'UPDATE_KYC_SUCCESS',
    UPDATE_KYC_FAILURE: 'UPDATE_KYC_FAILURE',

    UPDATE_DETAILS_REQUEST: 'UPDATE_DETAILS_REQUEST',
    UPDATE_DETAILS_SUCCESS: 'UPDATE_DETAILS_SUCCESS',
    UPDATE_DETAILS_FAILURE: 'UPDATE_DETAILS_FAILURE',

    CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
    CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
    CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',
};
