import { winnerConstants } from "../_constants";

export function winnerlist(state = {}, action) {
  console.log("action.typeaction.typeaction.type  ", action);

  switch (action.type) {
    case winnerConstants.GETALL_REQUEST_WINNER:
      return {
        loading: true,
        items: state.items,
        listOfwinnerlists: state.listOfwinnerlists,
        total: state.total
      };
      case winnerConstants.GETALL_SUCCESS_WINNER:
        return {

          items: action.winnerlist.listOfwinnerlists,
          listOfResource: state.listOfResource,
          total: action.winnerlist.listOfwinnerlists
        };

    case winnerConstants.GETALL_FAILURE_WINNER:
      return {
        error: action.error
      };


    default:
      return state
  }
}