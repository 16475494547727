import { CONST } from '../_config';

export const referralLevel = {
 

  GET_REFERRAL_LEVEL_REQUEST: "GET_REFERRAL_LEVEL_REQUEST",
  GET_REFERRAL_LEVEL_SUCCESS: "GET_REFERRAL_LEVEL_SUCCESS",
  GET_REFERRAL_LEVEL_FAILURE: "GET_REFERRAL_LEVEL_FAILURE",

  UPDATE_REFERRAL_LEVEL_REQUEST: "UPDATE_REFERRAL_LEVEL_REQUEST",
  UPDATE_REFERRAL_LEVEL_SUCCESS: "UPDATE_REFERRAL_LEVEL_SUCCESS",
  UPDATE_REFERRAL_LEVEL_FAILURE: "UPDATE_REFERRAL_LEVEL_FAILURE",


  GET_STATIC_PAGE_REQUEST: "GET_STATIC_PAGE_REQUEST",
  GET_STATIC_PAGE_SUCCESS: "GET_STATIC_PAGE_SUCCESS",
  GET_STATIC_PAGE_FAILURE: "GET_STATIC_PAGE_FAILURE",

  UPDATE_STATIC_PAGE_REQUEST: "UPDATE_STATIC_PAGE_REQUEST",
  UPDATE_STATIC_PAGE_SUCCESS: "UPDATE_STATIC_PAGE_SUCCESS",
  UPDATE_STATIC_PAGE_FAILURE: "UPDATE_STATIC_PAGE_FAILURE",

  GET_SLIDER_REQUEST: "GET_SLIDER_REQUEST",
  GET_SLIDER_SUCCESS: "GET_SLIDER_SUCCESS",
  GET_SLIDER_FAILURE: "GET_SLIDER_FAILURE",

  ADD_SLIDER_REQUEST: "ADD_SLIDER_REQUEST",
  ADD_SLIDER_SUCCESS: "ADD_SLIDER_SUCCESS",
  ADD_SLIDER_FAILURE: "ADD_SLIDER_FAILURE",

  UPDATE_SLIDER_REQUEST: "UPDATE_SLIDER_REQUEST",
  UPDATE_SLIDER_SUCCESS: "UPDATE_SLIDER_SUCCESS",
  UPDATE_SLIDER_FAILURE: "UPDATE_SLIDER_FAILURE",

  TITLE:CONST.TITLE
};