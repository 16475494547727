export const winnerConstants = {
    GETALL_REQUEST_WINNER: 'GETALL_REQUEST_WINNER',
    GETALL_SUCCESS_WINNER: 'GETALL_SUCCESS_WINNER',
    GETALL_FAILURE_WINNER: 'GETALL_FAILURE_WINNER',

    WINNER_LIST_ADD_SUCCESS: 'WINNER_LIST_ADD_SUCCESS',
    WINNER_LIST_ADD_FAILURE: 'WINNER_LIST_ADD_FAILURE',

    WINNER_LIST_DELETE_SUCCESS: 'WINNER_LIST_DELETE_SUCCESS',
    WINNER_LIST_DELETE_FAILURE: 'WINNER_LIST_DELETE_FAILURE',

    GET_RESOUCE_SUCCESS: 'GET_RESOUCE_SUCCESS',
    GET_RESOUCE_FAILURE: 'GET_RESOUCE_FAILURE',

    SAVE_ASSIGNED_RESOUCE_SUCCESS: 'SAVE_ASSIGNED_RESOUCE_SUCCESS',
    SAVE_ASSIGNED_RESOUCE_FAILURE: 'SAVE_ASSIGNED_RESOUCE_FAILURE',

    GET_ASSIGNED_RESOUCE_SUCCESS: 'GET_ASSIGNED_RESOUCE_SUCCESS',
    GET_ASSIGNED_RESOUCE_FAILURE: 'GET_ASSIGNED_RESOUCE_FAILURE',

    UPDATE_STATUS_REQUEST: 'UPDATE_STATUS_REQUEST',
    UPDATE_STATUS_SUCCESS: 'UPDATE_STATUS_SUCCESS',
    UPDATE_STATUS_FAILURE: 'UPDATE_STATUS_FAILURE',

    GET_SELMENU_REQUEST: 'GET_SELMENU_REQUEST',
    GET_SELMENU_SUCCESS: 'GET_SELMENU_SUCCESS',
    GET_SELMENU_FAILURE: 'GET_SELMENU_FAILURE',
};
