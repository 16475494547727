import { allmatchcontestConstants } from "../_constants/allmatchcontest.constants";

export function allmatchcontest(state = {}, action) {
  console.log("action.typeaction.typeaction.type  ", action.contenstsList)
  

  switch (action.type) {
    case allmatchcontestConstants.CONTEST_GETALL_REQUEST:
      return {
        loading: true
      };
    case allmatchcontestConstants.CONTEST_GETALL_SUCCESS:
      return {
        items: action.contest.listOfContests
      };
    case allmatchcontestConstants.CONTEST_GETALL_FAILURE:
      return {
        error: action.error
      };

      case allmatchcontestConstants.contestjoinedteamsall_GETALL_REQUEST:
        return {
          loading: true
        };
      case allmatchcontestConstants.contestjoinedteamsall_GETALL_SUCCESS:
        return {
          items: action.contenstsList.contestjoinedteamsall
        };
      case allmatchcontestConstants.contestjoinedteamsall_GETALL_FAILURE:
        return {
          error: action.error
        };



    case allmatchcontestConstants.CONTEST_UPDATE_STATUS_SUCCESS:
      return {
        ...state, isStatusUpdated: true
      };
    case allmatchcontestConstants.CONTEST_UPDATE_STATUS_FAILURE:
      return {
        error: action.error
      };

    case allmatchcontestConstants.CONTEST_ADD_STATUS_SUCCESS:
      return {
        ...state, isContenstAdded: true
      };
    case allmatchcontestConstants.CONTEST_ADD_STATUS_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}