import { CONST } from '../_config';

export const settingConstants = {
  GETALL_REQUEST: "SETTING_GETALL_REQUEST",
  GETALL_SUCCESS: "SETTING_GETALL_SUCCESS",
  GETALL_FAILURE: "SETTING_GETALL_FAILURE",

  GET_MAIN_SETTING_REQUEST: "SETTING_GET_MAIN_SETTING_REQUEST",
  GET_MAIN_SETTING_SUCCESS: "SETTING_GET_MAIN_SETTING_SUCCESS",
  GET_MAIN_SETTING_FAILURE: "SETTING_GET_MAIN_SETTING_FAILURE",
  
  GET_REFERRAL_LEVEL_REQUEST: "GET_REFERRAL_LEVEL_REQUEST",
  GET_REFERRAL_LEVEL_SUCCESS: "GET_REFERRAL_LEVEL_SUCCESS",
  GET_REFERRAL_LEVEL_FAILURE: "GET_REFERRAL_LEVEL_FAILURE",
 
  UPDATE_REFERRAL_LEVEL_REQUEST: "UPDATE_REFERRAL_LEVEL_REQUEST",
  UPDATE_REFERRAL_LEVEL_SUCCESS: "UPDATE_REFERRAL_LEVEL_SUCCESS",
  UPDATE_REFERRAL_LEVEL_FAILURE: "UPDATE_REFERRAL_LEVEL_FAILURE",


  ADD_SUCCESS: "SETTING_ADD_SUCCESS",
  ADD_FAILURE: "SETTING_ADD_FAILURE",

  UPDATE_MAIN_SETTING_SUCCESS: "SETTING_UPDATE_MAIN_SETTING_SUCCESS",
  UPDATE_MAIN_SETTING_FAILURE: "SETTING_UPDATE_MAIN_SETTING_FAILURE",


  GET_CAPTAIN_VC_SETTING_REQUEST: "SETTING_GET_CAPTAIN_VC_SETTING_REQUEST",
  GET_CAPTAIN_VC_SETTING_SUCCESS: "SETTING_GET_CAPTAIN_VC_SETTING_SUCCESS",
  GET_CAPTAIN_VC_SETTING_FAILURE: "SETTING_GET_CAPTAIN_VC_SETTING_FAILURE",

  UPDATE_CAPTAIN_VC_SETTING_SUCCESS: "SETTING_UPDATE_CAPTAIN_VC_SETTING_SUCCESS",
  UPDATE_CAPTAIN_VC_SETTING_FAILURE: "SETTING_UPDATE_CAPTAIN_VC_SETTING_FAILURE",


  UPDATE_CAPTAIN_VC_SETTING_SUCCESS1: "SETTING_UPDATE_CAPTAIN_VC_SETTING_SUCCESS1",
  UPDATE_CAPTAIN_VC_SETTING_FAILURE1: "SETTING_UPDATE_CAPTAIN_VC_SETTING_FAILURE1",

  UPDATE_CRICKET_MATCH_SETTING_SUCCESS: "SETTING_UPDATE_CAPTAIN_VC_SETTING_SUCCESS1",
  UPDATE_CRICKET_MATCH_SETTING_FAILURE: "SETTING_UPDATE_CAPTAIN_VC_SETTING_FAILURE1",

  GET_GAME_TYPE_SUCCESS: "GET_GAME_TYPE_SUCCESS",
  GET_GAME_TYPE__FAILURE: "GET_GAME_TYPE__FAILURE",

  GET_REFERRAL_REQUEST: "GET_REFERRAL_REQUEST",
  GET_REFERRAL_SUCCESS: "GET_REFERRAL_SUCCESS",
  GET_REFERRAL_FAILURE: "GET_REFERRAL_FAILURE",

  UPDATE_REFERRAL_REQUEST: "UPDATE_REFERRAL_REQUEST",
  UPDATE_REFERRAL_SUCCESS: "UPDATE_REFERRAL_SUCCESS",
  UPDATE_REFERRAL_FAILURE: "UPDATE_REFERRAL_FAILURE",


  GET_STATIC_PAGE_REQUEST: "GET_STATIC_PAGE_REQUEST",
  GET_STATIC_PAGE_SUCCESS: "GET_STATIC_PAGE_SUCCESS",
  GET_STATIC_PAGE_FAILURE: "GET_STATIC_PAGE_FAILURE",

  UPDATE_STATIC_PAGE_REQUEST: "UPDATE_STATIC_PAGE_REQUEST",
  UPDATE_STATIC_PAGE_SUCCESS: "UPDATE_STATIC_PAGE_SUCCESS",
  UPDATE_STATIC_PAGE_FAILURE: "UPDATE_STATIC_PAGE_FAILURE",

  GET_SLIDER_REQUEST: "GET_SLIDER_REQUEST",
  GET_SLIDER_SUCCESS: "GET_SLIDER_SUCCESS",
  GET_SLIDER_FAILURE: "GET_SLIDER_FAILURE",

  ADD_SLIDER_REQUEST: "ADD_SLIDER_REQUEST",
  ADD_SLIDER_SUCCESS: "ADD_SLIDER_SUCCESS",
  ADD_SLIDER_FAILURE: "ADD_SLIDER_FAILURE",

  UPDATE_SLIDER_REQUEST: "UPDATE_SLIDER_REQUEST",
  UPDATE_SLIDER_SUCCESS: "UPDATE_SLIDER_SUCCESS",
  UPDATE_SLIDER_FAILURE: "UPDATE_SLIDER_FAILURE",

  TITLE:CONST.TITLE
};